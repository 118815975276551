import { useState } from 'react';
import cx from 'classnames';
import Dropdown from 'components/Dropdown/Dropdown';
import AccountAlt from 'components/Icons/AccountAlt';
import NoSsr from 'utils/NoSsr';
import {
  MenuActionClickHandler,
  UserMenuAction
} from './Shared/useHeaderHelpers';
import ProductTag from 'components/Others/ProductTag';
import { useRouter } from 'next/router';

interface HeaderNavigationUserProps {
  loggedUser?: {
    firstName: string;
  };
  // eslint-disable-next-line no-unused-vars
  onMenuActionClicked: MenuActionClickHandler;
}
const HeaderNavigationUser = ({
  loggedUser,
  onMenuActionClicked
}: HeaderNavigationUserProps) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <Dropdown
      onToggle={setIsActive}
      className="flex h-full"
      contentWrapperClassName="w-[227px] !left-auto right-0 shadow-navbarOverlay max-h-auto !rounded-none !mt-0 !px-0 !py-0"
      contentWrapperParentClassName="!max-h-none"
      interactionType="MOUSEOVER"
      content={
        <UserMenu
          isLogged={!!loggedUser}
          onMenuActionClicked={onMenuActionClicked}
        />
      }>
      <div className="flex h-full cursor-pointer pt-[2px]">
        <div className="mb-[3px] flex items-center">
          <HelloMessage firstName={loggedUser?.firstName} />
        </div>
        <div
          className={cx(
            'ml-[10px] box-content flex items-center border-b-[3px] transition-colors',
            {
              'border-transparent text-brand-black100': !isActive,
              'border-brand-orange text-brand-orange': isActive
            }
          )}>
          <AccountAlt fill="currentColor" />
        </div>
      </div>
    </Dropdown>
  );
};

const HelloMessage = ({ firstName }: { firstName?: string }) => (
  <NoSsr>
    {firstName ? (
      <span className="max-w-[120px] overflow-hidden overflow-ellipsis whitespace-nowrap break-all text-[12px] leading-[16px] shop:text-[14px]">
        Hi <span className="font-medium">{firstName}!</span>
      </span>
    ) : (
      <span className="text-[12px] leading-[22px] shop:text-[14px]">
        Hello!
      </span>
    )}
  </NoSsr>
);

const UserMenu = ({
  onMenuActionClicked,
  onToggle,
  isLogged
}: {
  isLogged: boolean;
  onMenuActionClicked: MenuActionClickHandler;
  onToggle?: () => void;
}) => {
  const menuActionClickHandler: MenuActionClickHandler = (action) => {
    if (onToggle) onToggle();
    onMenuActionClicked(action);
  };

  return isLogged ? (
    <LoggedInUserMenu onMenuActionClicked={menuActionClickHandler} />
  ) : (
    <NotLoggedInUserMenu onMenuActionClicked={menuActionClickHandler} />
  );
};

const LoggedInUserMenu = ({
  onMenuActionClicked
}: {
  onMenuActionClicked: MenuActionClickHandler;
}) => {
  const router = useRouter();
  return (
    <div className="py-[15px]">
      <div className="flex flex-col items-start px-[20px]">
        <button
          onClick={() => onMenuActionClicked(UserMenuAction.myOrders)}
          className="mb-[15px] block text-brand-black100 transition-colors duration-500 hover:text-brand-orange">
          My Orders
        </button>
        <button
          onClick={() => onMenuActionClicked(UserMenuAction.myPrescriptions)}
          className="mb-[15px] block text-brand-black100 transition-colors duration-500 hover:text-brand-orange">
          Prescriptions
        </button>
        <button
          onClick={() => onMenuActionClicked(UserMenuAction.myProfile)}
          className="mb-[15px] block text-brand-black100 transition-colors duration-500 hover:text-brand-orange">
          My Profile
        </button>
        <button
          onClick={() => onMenuActionClicked(UserMenuAction.addresses)}
          className="mb-[15px] block text-brand-black100 transition-colors duration-500 hover:text-brand-orange">
          Addresses
        </button>
        <button
          onClick={() => onMenuActionClicked(UserMenuAction.referral)}
          className="mb-[15px] flex block text-brand-black100 transition-colors duration-500 hover:text-brand-orange">
          Referral Program <span className='ml-2 mt-1'><ProductTag isSmall={true} text={'New'} isAbsolute={false} textColor="#fff" backgroundColor="#FF8200" /></span>
        </button>

        {(router.locale == 'au' || router.locale == 'ca') && (
          <button
            onClick={() => onMenuActionClicked(UserMenuAction.reOrderContactLenses)}
            className="mb-[15px] flex block text-brand-black100 transition-colors duration-500 hover:text-brand-orange">
            Re-order Contact Lenses
          </button>
        )}

      </div>
      <div className="border-t border-brand-grey500 px-[20px]">
        <button
          onClick={() => onMenuActionClicked(UserMenuAction.logout)}
          className="mt-[10px] block text-brand-grey300 transition-colors duration-500 hover:text-brand-orange">
          Log Out
        </button>
      </div>
    </div>
  );
};

const NotLoggedInUserMenu = ({
  onMenuActionClicked
}: {
  onMenuActionClicked: MenuActionClickHandler;
}) => {
  const router = useRouter();
  return (
    <div className="py-[20px]">
      <div className="flex flex-col border-b border-brand-grey500 px-[20px]">
        <button
          onClick={() => onMenuActionClicked(UserMenuAction.signIn)}
          className="flex h-[44px] w-full justify-center rounded-[6px] bg-brand-blue text-center font-medium leading-[40px] text-white transition-all duration-500 hover:bg-brand-orange">
          Sign In
        </button>
        <button
          onClick={() => onMenuActionClicked(UserMenuAction.signUp)}
          className="my-[20px] block text-center font-medium text-brand-black100 transition-colors duration-500 hover:text-brand-orange">
          Create New Account
        </button>
      </div>
      <div className="flex flex-col items-start px-[20px] pt-[20px]">
        <button
          onClick={() => onMenuActionClicked(UserMenuAction.myOrders)}
          className="mb-[16px] block text-brand-black100 transition-colors duration-500 hover:text-brand-orange">
          My Orders
        </button>
        <button
          onClick={() => onMenuActionClicked(UserMenuAction.myPrescriptions)}
          className="mb-[16px] block text-brand-black100 transition-colors duration-500 hover:text-brand-orange">
          My Prescriptions
        </button>
        <button
          onClick={() => onMenuActionClicked(UserMenuAction.myProfile)}
          className="mb-[16px] block text-brand-black100 transition-colors duration-500 hover:text-brand-orange">
          My Profile
        </button>

        {(router.locale == 'au' || router.locale == 'ca') && (
          <button
            onClick={() => onMenuActionClicked(UserMenuAction.reOrderContactLenses)}
            className="mb-[15px] flex block text-brand-black100 transition-colors duration-500 hover:text-brand-orange">
            Re-order Contact Lenses
          </button>
        )}

        <button
          onClick={() => onMenuActionClicked(UserMenuAction.referral)}
          className="block flex text-brand-black100 transition-colors duration-500 hover:text-brand-orange">
          Referral Program <span className='ml-2 mt-1'><ProductTag isSmall={true} text={'New'} isAbsolute={false} textColor="#fff" backgroundColor="#FF8200" /></span>
        </button>

      </div>
    </div>
  );
};

export default HeaderNavigationUser;
